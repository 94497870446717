import SectionHero from "./SectionHero";
import React from "react";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionHowItWork from "../../components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "../components/BackgroundSection";
import BgGlassmorphism from "../components/BgGlassmorphism";
import TourCardGrid from "../tours/TourCardGrid";
import { graphql, useStaticQuery } from "gatsby";
import SectionCarouselFleet from "./SectionCarouselFleet";
import GoogleTranslate from '../../components/googleTranslate'
import SectionSliderNewCategories from "../components/SectionSliderNewCategories";
import HeroSearchForm from "./HeroSearchForm";


function PageHome() {
  const data = useStaticQuery(graphql`
    {
      transfers: allStrapiTransfers(filter: { showOnHomePage: { eq: true } }) {
        nodes {
          name: Title
          strapiId
          href
          transferPrice
          MainImage {
            url
          }
        }
      }
      allStrapiTours(filter: { showOnHomePage: { eq: true } }) {
        nodes {
          address: tags
          title
          link: href
          id
          galleryImgs {
            url
          }
          prices {
            price
          }
        }
      }
      allStrapiClients(filter: { showOnHomePage: { eq: true } }) {
        nodes {
          image {
            url
          }
          strapiId
          description
        }
      }
      allStrapiCarouselFleets(sort: { fields: OrderInCarousel, order: ASC }) {
        nodes {
          title: VehicleCategoryName
          galleryImgs: Images {
            url
          }
          OrderInCarousel
          id: strapiId
        }
      }
       allStrapiVehicleLists(sort: { fields: Order, order: ASC }) {
        nodes {
          EnumId
          id
          CategoryName
          MaxLuggage
          MaxPeople
          Image {
            url
            id
          }
          Carousel_Images {
            url
            id
          }
          Order
          VehicleName
        }
      }
    }
  `);

  data.allStrapiClients.nodes.forEach((item) => {
    item.thumbnail = item.image[0].url;
  });

  data.allStrapiCarouselFleets.nodes.forEach((vehicle) => {
    vehicle.href = vehicle.id;
    vehicle.listingCategory = {
      count: 2885,
      href: "stay/href",
      id: 17,
      name: "Entire cabin",
      taxonomy: "category",
      thumbnail: vehicle.galleryImgs[0],
    };
  });

  data.allStrapiTours.nodes.forEach((tour) => {
    const precision = 10;
    tour.reviewStart = Math.floor(Math.random() * (5 * precision - 4 * precision) + 4 * precision) / (1 * precision);
    tour.reviewCount = Math.floor(Math.random() * (Math.floor(200) - Math.ceil(20)) + Math.ceil(20));

    tour.featuredImage = tour.galleryImgs[0];
    tour.href = `/tours/${tour.link}`;
  });

  data.transfers.nodes.forEach((transfer) => {
    transfer.thumbnail = transfer.MainImage.url;
    transfer.listingType = "transfer";
    transfer.count = transfer.transferPrice;
  });

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <GoogleTranslate />

      {/*<BgGlassmorphism />*/}

      <div id="transfers" className="container relative space-y-24 mb-24 lg:space-y-32 lg:mb-32">
        {/* SECTION HERO */}
        <SectionHero className="pt-5 md:pt-10 lg:pt-2 pb-2"/>

        {/* TOURS */}
        <div className="relative py-10">
          <BackgroundSection/>
          <TourCardGrid
              heading={"Suggested Tours"}
              subHeading={"You Will Find Bellow Related Tours"}
              stayListings={data.allStrapiTours.nodes}
              headingIsCenter={true}
          />
        </div>

        {/*/!*TRANSFERS*!/*/}
        {/*<div className="relative py-10">*/}
        {/*  <SectionSliderNewCategories*/}
        {/*      categories={data.transfers.nodes}*/}
        {/*      heading={"Popular Locations"}*/}
        {/*      subHeading={"Choose among the most popular routes"}*/}
        {/*  />*/}
        {/*</div>*/}

        {/* Fleet */}
        <div className="relative py-16" id="ourFleet">
          <BackgroundSection/>
          <SectionCarouselFleet
              stayListings={data.allStrapiCarouselFleets.nodes}
              categoryCardType="card4"
              itemPerRow={4}
              heading="Our Fleet"
              subHeading="Find the right vehicle to feet your every need"
              sliderStyle="style2"
          />
        </div>

        {/* SECTION2 */}
        <SectionOurFeatures/>

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection/>
          <SectionHowItWork isCenter={false}/>
        </div>

        {/* SECTION 1 */}
        {/*<div className="relative py-16">*/}
        {/*  <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />*/}
        {/*  <SectionSliderNewCategories*/}
        {/*    categories={DEMO_CATS_2}*/}
        {/*    categoryCardType="card4"*/}
        {/*    itemPerRow={4}*/}
        {/*    heading="Suggestions for discovery"*/}
        {/*    subHeading="Popular places to stay that Chisfis recommends for you"*/}
        {/*    sliderStyle="style2"*/}
        {/*  />*/}
        {/*</div>*/}

        {/* SECTION */}
        {/*<SectionSubscribe2 />*/}

        {/* SECTION */}
        {/*<div className="relative py-16">*/}
        {/*  <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />*/}
        {/*  <SectionGridAuthorBox />*/}
        {/*</div>*/}

        {/* SECTION */}
        {/*<SectionGridCategoryBox />*/}

        {/* SECTION */}
        {/*<div className="relative py-16">*/}
        {/*  <BackgroundSection />*/}
        {/*  <SectionBecomeAnAuthor />*/}
        {/*</div>*/}

        {/* SECTION 1 */}
        {/*<SectionSliderNewCategories*/}
        {/*  heading="Explore by types of stays"*/}
        {/*  subHeading="Explore houses based on 10 types of stays"*/}
        {/*  categoryCardType="card5"*/}
        {/*  itemPerRow={5}*/}
        {/*/>*/}

        {/* SECTION */}
        {/*<SectionVideos />*/}

        {/* SECTION */}
        {/*<SectionClientSay />*/}
      </div>
    </div>
  );
}

export default PageHome;
